<template>
    <div class="ui stackable grid m-0">
        <div class="row border-bottom">
            <div class="eight wide left aligned column">
                <h1 class="ui grey header"> Editer Action </h1>
            </div>
            <div class="eight wide right aligned column">
                <div class="ui primary tiny button" @click="save">
                    <i class="check circle icon"></i>
                    Enregistrer
                </div>
                <div class="ui button" @click="$router.push({name: 'actionList'})">
                    <i class="times circle icon"></i>
                    Cancel
                </div>
            </div>
        </div>

        <div class="row">
            <div class="column">
                    <div class="ui form">
                        <div class="field">
                            <label>Action</label>
                            <input type="text" placeholder="Action" style="font-weight: bold;" :value="action.name" disabled>
                        </div>

                        <div class="two fields">

                            <div class="field">
                                <label>Temps d'exécution</label>
                                <div class="ui right labeled input">
                                    <input type="number" min="0" placeholder="Temps d'exécution" v-model.number="action.temps_exec">
                                    <div class="ui green label">
                                        <div class="text">Minute(s)</div>
                                    </div>
                                </div>
                            </div>

                            <div class="field">
                                <label>Temps de réalisation</label>
                                <div class="ui right labeled input">
                                    <input type="number" placeholder="Temps de réalisation" v-model.number="action.temps_realis">
                                    <div class="ui green label">
                                        <div class="text">Heure(s)</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "actionCrud",
        props:{
            current_action: {
                type: Object,
                default:() => {
                    return {}
                }
            }
        },
        data(){
          return{
              action: {
                  temps_exec: 0,
                  temps_realis: 0
              }
          }
        },
        methods:{
            save(){
                log('save action:', this.action);

                let data = {
                    collection: this.$SHARED.collections.action,
                    query: {
                        _id: this.action._id
                    },
                    update: {'$set': this.action},
                    remove_id: true
                };

                this.$store.requestUpdate(data).then(() => {
                    this.$store.dispatch('get_actions')
                    this.$router.push({name: 'actionList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.action.update_succeeded
                    });
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                });
            }
        },
        mounted() {
            if(this.current_action.hasOwnProperty('_id')){
                this.action = JSON.parse(JSON.stringify(this.current_action));
            }
            $('.ui.checkbox').checkbox();
        }
    }
</script>

<style scoped>
    .border-bottom{
        border-bottom: 2px solid #767676;
    }
</style>